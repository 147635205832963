import { useState } from 'react'

import { Grid } from '@mantine/core'
import dayjs from 'dayjs'

import { Metadata, useQuery } from '@redwoodjs/web'

import {
  GET_ACTIVITY_STATUS_LOG_METRICS,
  GET_CONVERSION_STATUS_LOG_METRICS,
  GET_DISPOSITION_STATUS_LOG_METRICS,
} from 'src/graphql/statusLogs.graphql'

import AnalyticsCard from './components/AnalyticsCard'
import DispositionTable from './components/DispositionTable'
import LeadConversionTable from './components/LeadConversionTable'
import PipelineAreaChart from './components/PipelineAreaChart'
import SparklineCard from './components/SparklineCard'
import FiltersBar from './FiltersBar'
import { generateMetricsByDateRange } from './utils'

const AnalyticsPage = () => {
  const recruitersUrlString = new URLSearchParams(window.location.search).get(
    'recruiters'
  )
  const selectedRecruitersFromUrl = recruitersUrlString
    ? recruitersUrlString.split(',')
    : []

  const leadSourcesUrlString = new URLSearchParams(window.location.search).get(
    'leadSources'
  )
  const selectedLeadSourcesFromUrl = leadSourcesUrlString
    ? leadSourcesUrlString.split(',')
    : []
  const jobListingsUrlString = new URLSearchParams(window.location.search).get(
    'jobListings'
  )
  const selectedJobListingsFromUrl = jobListingsUrlString
    ? jobListingsUrlString.split(',')
    : []

  const dateRangeUrlString = new URLSearchParams(window.location.search).get(
    'dateRange'
  )
  const dateRange = dateRangeUrlString
    ? dateRangeUrlString.split(',')
    : [dayjs().subtract(7, 'day').startOf('day').toDate(), new Date()]
  const defaultStartDate = new Date(dateRange[0])
  const defaultEndDate = new Date(dateRange[1])
  const [selectedRecruiters, setSelectedRecruiters] = useState(
    selectedRecruitersFromUrl
  )
  const [selectedLeadSources, setSelectedLeadSources] = useState(
    selectedLeadSourcesFromUrl
  )
  const [selectedJobListings, setSelectedJobListings] = useState(
    selectedJobListingsFromUrl
  )
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  /**********************    QUERIES    **********************/

  const {
    data: activityStatusLogMetrics,
    loading: activityStatusLogMetricsLoading,
  } = useQuery(GET_ACTIVITY_STATUS_LOG_METRICS, {
    variables: {
      filters: {
        recruiters: selectedRecruiters,
        jobListings: selectedJobListings,
        linkTypes: selectedLeadSources,
        dateRange: {
          gte: startDate,
          lte: endDate,
        },
      },
    },
  })

  const { data: conversionLogMetrics, loading: conversionLogMetricsLoading } =
    useQuery(GET_CONVERSION_STATUS_LOG_METRICS, {
      variables: {
        filters: {
          recruiters: selectedRecruiters,
          jobListings: selectedJobListings,
          linkTypes: selectedLeadSources,
          dateRange: {
            gte: startDate,
            lte: endDate,
          },
        },
      },
    })

  const { data: dispositionMetrics, loading: dispositionMetricsLoading } =
    useQuery(GET_DISPOSITION_STATUS_LOG_METRICS, {
      variables: {
        filters: {
          recruiters: selectedRecruiters,
          jobListings: selectedJobListings,
          linkTypes: selectedLeadSources,
          dateRange: {
            gte: startDate,
            lte: endDate,
          },
        },
      },
    })

  const pipelineData = generateMetricsByDateRange(
    startDate,
    endDate,
    activityStatusLogMetrics?.activityStatusLogMetrics || []
  )

  return (
    <div className="flex h-full flex-col gap-4 overflow-hidden px-5 pb-5">
      <Metadata title="Analytics" description="Analytics page" />
      <div className="text-lg font-semibold text-doubleNickel-gray-900">
        Pipeline Analytics
      </div>
      <FiltersBar
        selectedRecruiters={selectedRecruiters}
        setSelectedRecruiters={setSelectedRecruiters}
        selectedJobListings={selectedJobListings}
        setSelectedJobListings={setSelectedJobListings}
        selectedLeadSources={selectedLeadSources}
        setSelectedLeadSources={setSelectedLeadSources}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <Grid className="h-full overflow-y-auto overflow-x-hidden">
        <Grid.Col span={4}>
          <AnalyticsCard
            title="Summary of Hiring Activity"
            loading={activityStatusLogMetricsLoading}
            description={'Changes in status across all applicants & drivers'}
          >
            <SparklineCard pipelineMetrics={pipelineData} />
          </AnalyticsCard>
        </Grid.Col>
        <Grid.Col span={8}>
          <AnalyticsCard
            title="Daily Hiring Activity"
            loading={activityStatusLogMetricsLoading}
            description={
              'Daily prospects & changes in status across all applicants & current drivers'
            }
          >
            <PipelineAreaChart type={'default'} data={pipelineData} />
          </AnalyticsCard>
        </Grid.Col>

        <Grid.Col span={12}>
          <AnalyticsCard
            title="Prospect Conversion Funnel"
            loading={conversionLogMetricsLoading}
            description={
              'Current status for prospects that applied within the selected time period'
            }
          >
            <LeadConversionTable
              conversionLogMetrics={
                conversionLogMetrics?.conversionStatusLogMetrics || []
              }
            />
          </AnalyticsCard>
        </Grid.Col>
        <Grid.Col span={12}>
          <AnalyticsCard
            title="Prospect Final Disposition Reason"
            loading={dispositionMetricsLoading}
            description={
              'Not Interested & Disqualified Reasons for prospects that applied within the selected time period'
            }
          >
            <DispositionTable
              dispositonMetrics={
                dispositionMetrics?.dispositionStatusLogMetrics || []
              }
            />
          </AnalyticsCard>
        </Grid.Col>
      </Grid>
    </div>
  )
}

export default AnalyticsPage

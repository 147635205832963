import { useContext } from 'react'

import { Divider } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import Tag from 'src/components/DataDisplay/Tag/Tag'
import FilterMenu from 'src/components/Overlays/FiltersMenu/FilterMenu'
import { RecruitersContext } from 'src/context/RecruitersContext'
import { GET_JOB_LISTINGS_ONLY } from 'src/graphql/joblistings.graphql'
import { LinkType, LinkTypeDisplay } from 'src/graphql/types/trackingLinks'

import DateRangeMenu from '../../components/Overlays/DateRangeMenu'

const FiltersBar = ({
  selectedRecruiters,
  setSelectedRecruiters,
  selectedJobListings,
  setSelectedJobListings,
  selectedLeadSources,
  setSelectedLeadSources,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const leadSourceOptions = Object.keys(LinkType).map((key) => ({
    value: LinkType[key],
    label: LinkTypeDisplay[LinkType[key]],
  }))
  const recruiters = useContext(RecruitersContext)
  /**********************    QUERIES    **********************/

  const { data: jobListingsResponse } = useQuery(GET_JOB_LISTINGS_ONLY, {
    variables: {
      pageInfo: { offset: 0, limit: 100 },
      filters: {
        recruiters: selectedRecruiters,
      },
    },
  })

  const jobListingOptions =
    jobListingsResponse?.jobListings.items?.map((jl) => ({
      value: jl.jobListingId,
      label: jl.title,
    })) || []

  const updateUrl = (name, value) => {
    const url = new URL(window.location.href)
    if (!value) {
      url.searchParams.delete(name)
    } else {
      url.searchParams.set(name, value)
    }
    window.history.replaceState(null, null, url)
  }

  return (
    <div className="flex flex-row items-center gap-4">
      <div className="flex flex-1 flex-row gap-2 overflow-auto">
        {selectedRecruiters.map((recruiter) => (
          <Tag
            key={recruiter}
            onClose={() => {
              const newRecruiters = selectedRecruiters.filter(
                (item) => item !== recruiter
              )
              updateUrl('recruiters', newRecruiters.join(','))
              setSelectedRecruiters(newRecruiters)
            }}
          >
            {recruiters.find((r) => r.value === recruiter)?.label}
          </Tag>
        ))}
        {selectedJobListings.map((jobListing) => {
          const matchingOption = jobListingOptions.find(
            (j) => j.value === jobListing
          )
          if (!matchingOption) return null

          return (
            <Tag
              key={jobListing}
              onClose={() => {
                const newJobListings = selectedJobListings.filter(
                  (j) => j !== jobListing
                )
                setSelectedJobListings(newJobListings)
                updateUrl('jobListings', newJobListings.join(','))
              }}
            >
              {matchingOption.label}
            </Tag>
          )
        })}
        {selectedLeadSources.map((leadSource) => (
          <Tag
            key={leadSource}
            onClose={() => {
              const newLeadSources = selectedLeadSources.filter(
                (l) => l !== leadSource
              )
              setSelectedLeadSources(newLeadSources)
              updateUrl('leadSources', newLeadSources.join(','))
            }}
          >
            {LinkTypeDisplay[leadSource]}
          </Tag>
        ))}
      </div>

      <Divider orientation="vertical" className="ml-auto" />
      <FilterMenu
        filters={[
          {
            title: 'Recruiters',
            selected: selectedRecruiters,
            onChange: (value) => {
              setSelectedRecruiters(value)
              updateUrl('recruiters', value.join(','))
            },
            options: recruiters ? [...recruiters] : [],
          },
          {
            title: 'Job Listings',
            selected: selectedJobListings,
            onChange: (value) => {
              setSelectedJobListings(value)
              updateUrl('jobListings', value.join(','))
            },
            options: jobListingOptions ? [...jobListingOptions] : [],
          },
          {
            title: 'Lead Source',
            selected: selectedLeadSources,
            onChange: (value) => {
              setSelectedLeadSources(value)
              updateUrl('leadSources', value.join(','))
            },
            options: leadSourceOptions ? [...leadSourceOptions] : [],
          },
        ]}
      />
      <DateRangeMenu
        minDate={new Date('2024-10-15T12:00:00.000Z')}
        startDate={startDate}
        endDate={endDate}
        handleChange={(value) => {
          setStartDate(value[0])
          setEndDate(value[1])
          updateUrl('dateRange', value.join(','))
        }}
      />
    </div>
  )
}

export default FiltersBar
